import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './pages/home';
import Loan from './pages/loan_advisory';
import Finance from './pages/financial_advisory';
import Tax from './pages/tax_advisory';
import Business from './pages/business_advisory'    
import Tech from './pages/IT_advisory';
import Contact from './pages/contact';


function Routers () {
      return (
        <Router>
          <Routes>
            <Route path="/" exact element={<Home/>}/>
            <Route path="/loan_advisory" exact element={<Loan/>}/>        
            <Route path="/financial_advisory" exact element={<Finance/>}/>   
            <Route path="/tax_advisory" exact element={<Tax/>}/>
            <Route path="/business_advisory" exact element={<Business/>}/>
            <Route path="/IT_advisory" exact element={<Tech/>}/>  
            <Route path="/contact" exact element={<Contact/>}/>
            
        </Routes>
    </Router>
      )
}
    
  
  export default Routers;