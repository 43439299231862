import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Contactnow  from '../components/contact';
import Card from 'react-bootstrap/Card';
import Header from '../components/innerHeader'

const Tax_advisory = () => {
    return(
        <div>
          <Header/>  
        <div fluid="md" className='businessarticals'>
            <div className='grid_container'>
               <Col className='businessContainer'>
                  <img class="d-block w-100" src="assets/tax_advisory.webp" alt="First slide"/>
                  <Row> 
                    <div class="caption"><h5>Tax Advisory </h5><p>We Guiding you to minimize tax risk and optimize their financial decisions to reduce overall taxes Through Expert Advisory </p></div>
                        <a href="#whatyouwant" class="contactteam btn btn-primary btn-lg">For More Info Contact Us</a>
                  </Row>
               </Col>
            </div>

            <Col className='card_container'>
            <Col className='grid-col-articals'>
                    <Card className='mb-2 card bg-light text-dark'>
                        <Card.Body>
                            <Card.Title>Income Tax Basics</Card.Title>
                            <Card.Text>

                                "Understanding the Basics of Income Tax in India"
                                "Income Tax Slabs and Rates for Individuals"
                                "Components of Income: Taxable and Non-Taxable"
                            </Card.Text>
                            <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
                        </Card.Body>
                    </Card>
                    </Col>
                    <Col className='grid-col-articals'>
                    <Card className='mb-2 card bg-light text-dark'>
                        <Card.Body>
                            <Card.Title>Tax Saving Strategies</Card.Title>
                            <Card.Text>

                                "Effective Tax Planning Tips for Indian Taxpayers"
                                "Popular Tax-Saving Investments under Section 80C"
                                "Maximizing Deductions: A Guide to Save on Taxes"
                            </Card.Text>
                            <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
                        </Card.Body>
                    </Card>
                    </Col>
                    <Col className='grid-col-articals'>
                    <Card className='mb-2 card bg-light text-dark'>
                        <Card.Body>
                            <Card.Title>Goods and Services Tax (GST)</Card.Title>
                            <Card.Text>

                                "Comprehensive Guide to Goods and Services Tax (GST)"
                                "GST Rates for Different Goods and Services"
                                "GST Filing: Procedures and Compliance"
                            </Card.Text>
                            <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
                        </Card.Body>
                    </Card>
                    </Col>
                    <Col className='grid-col-articals'>
                    <Card className='mb-2 card bg-light text-dark'>
                        <Card.Body>
                            <Card.Title>Business Taxation</Card.Title>
                            <Card.Text>

                                "Corporate Taxation in India: Key Considerations"
                                "Tax Implications for Small Businesses and Startups"
                                "GST for Businesses: Input Tax Credit and Compliance"
                            </Card.Text>
                            <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
                        </Card.Body>
                    </Card>
                    </Col>
                    <Col className='grid-col-articals'>
                    <Card className='mb-2 card bg-light text-dark'>
                        <Card.Body>
                            <Card.Title>International Taxation</Card.Title>
                            <Card.Text>

                                "Tax Implications for NRIs (Non-Resident Indians)"
                                "Transfer Pricing Regulations in India"
                                "Double Taxation Avoidance Agreements (DTAA)"
                            </Card.Text>
                            <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
                        </Card.Body>
                    </Card>
                    </Col>
                    <Col className='grid-col-articals'>
                    <Card className='mb-2 card bg-light text-dark'>
                        <Card.Body>
                            <Card.Title>Real Estate and Property Tax</Card.Title>
                            <Card.Text>

                                "Tax Considerations for Homebuyers in India"
                                "Property Tax Rules and Calculations"
                                "Capital Gains Tax on Real Estate Transactions"
                            </Card.Text>
                            <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
                        </Card.Body>
                    </Card>
                    </Col>
                    <Col className='grid-col-articals'>
                    <Card className='mb-2 card bg-light text-dark'>
                        <Card.Body>
                            <Card.Title>Tax Deductions and Exemptions</Card.Title>
                            <Card.Text>

                                "Common Deductions Available to Indian Taxpayers"
                                "Exemptions under Section 10 of the Income Tax Act"
                                "HRA (House Rent Allowance) Calculations"
                            </Card.Text>
                            <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
                        </Card.Body>
                    </Card>
                    </Col>
                    <Col className='grid-col-articals'>
                    <Card className='mb-2 card bg-light text-dark'>
                        <Card.Body>
                            <Card.Title>Tax Compliance and Filing</Card.Title>
                            <Card.Text>

                                "E-Filing of Income Tax Returns: A Step-by-Step Guide"
                                "Due Dates for Filing Different Tax Returns"
                                "Penalties for Late or Non-Filing of Taxes"
                            </Card.Text>
                            <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
                        </Card.Body>
                    </Card>
                    </Col>
                    <Col className='grid-col-articals'>
                    <Card className='mb-2 card bg-light text-dark'>
                        <Card.Body>
                            <Card.Title>Tax for Investors</Card.Title>
                            <Card.Text>

                                "Taxation of Capital Gains on Investments"
                                "Dividend Distribution Tax (DDT): Impact on Investors"
                                "Tax-Efficient Investment Strategies for Indians"
                            </Card.Text>
                            <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
                        </Card.Body>
                    </Card>
                    </Col>
                    <Col className='grid-col-articals'>
                    <Card className='mb-2 card bg-light text-dark'>
                        <Card.Body>
                            <Card.Title>Startups and Tax Incentives</Card.Title>
                            <Card.Text>

                                "Tax Benefits and Incentives for Startups in India"
                                "Angel Tax: Understanding the Issues and Solutions"
                                "Government Schemes Promoting Entrepreneurship"
                            </Card.Text>
                            <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
                        </Card.Body>
                    </Card>
                    </Col>
                    <Col className='grid-col-articals'>
                    <Card className='mb-2 card bg-light text-dark'>
                        <Card.Body>
                            <Card.Title>Tax Audits and Disputes</Card.Title>
                            <Card.Text>

                                "Handling Income Tax Audits: A Guide for Businesses"
                                "Common Reasons for Tax Disputes and Resolutions"
                                "Appellate Process in Case of Tax Disputes"
                            </Card.Text>
                            <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
                        </Card.Body>
                    </Card>
                    </Col>
                    <Col className='grid-col-articals'>
                    <Card className='mb-2 card bg-light text-dark'>
                        <Card.Body>
                            <Card.Title>Customs and Import Duties</Card.Title>
                            <Card.Text>

                                "Understanding Customs Duties and Tariffs in India"
                                "Import Duties and Procedures for Businesses"
                                "Export Incentives and Schemes"
                            </Card.Text>
                            <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
                        </Card.Body>
                    </Card>
                    </Col>
                    <Col className='grid-col-articals'>
                    <Card className='mb-2 card bg-light text-dark'>
                        <Card.Body>
                            <Card.Title>Tax on Digital Transactions</Card.Title>
                            <Card.Text>

                                "Tax Implications of Digital Transactions and E-commerce"
                                "Digital Payment Systems and Their Taxation"
                                "Tax Deducted at Source (TDS) for Online Transactions"
                            </Card.Text>
                            <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
                        </Card.Body>
                    </Card>
                    </Col>
                    <Col className='grid-col-articals'>
                    <Card className='mb-2 card bg-light text-dark'>
                        <Card.Body>
                            <Card.Title>Budget and Policy Updates</Card.Title>
                            <Card.Text>

                                "Analysis of the Union Budget and Its Impact on Taxation"
                                "Recent Policy Changes in Tax Laws: What You Need to Know"
                                "Economic Surveys and Their Implications for Taxpayers"
                            </Card.Text>
                            <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
                        </Card.Body>
                    </Card>
                    </Col>
            </Col>
            <div className='contactpage'>
               <Contactnow/>
            </div>
        </div>
        </div>
    )
}

export default Tax_advisory;