// import Container from 'react-bootstrap/Container';
// import Col from 'react-bootstrap/Col';
import React, {useRef} from 'react';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contactform = () => {
    const ConctactForm = useRef();


    const SendEmail = (e) => {
      e.preventDefault();
   
      const { username, email, message, service, mobile } = ConctactForm.current.elements;

      emailjs.sendForm('service_i2zikin', 'template_xueds29', ConctactForm.current,  'XWxwgdW6jBk8FsEla')
        .then((result) => {
            toast.success("Hi Your Message Successfully! Our team will contact you soon.");
            console.log(result.text)
        }, (error) => {
       
            toast.error("some technical error occurred. please try again after sometime/Contact us +91-9363353206/santebusinesssolutions@gmail.com ");
            console.log(error)
        });
    };
  
    return (
        <div className='formcontainer'>
       
       
        <Form className='querycontainer' id='Enquiryform' ref={ConctactForm}  onSubmit={SendEmail}>
            <FloatingLabel controlId="floatingInput" label="Name" className="mb-3">
                    <Form.Control type="text" name="username"  placeholder="Name" required/>
            </FloatingLabel>
            <FloatingLabel controlId="floatingInput" label="Email address" className="mb-3">
                <Form.Control type="email" name="email" placeholder="name@example.com" required/>
            </FloatingLabel>
            <FloatingLabel controlId="floatingInput" label="Mobile No." className="mb-3">
                <Form.Control type="" name="mobile" placeholder="Enter your Mobile no." required/>
            </FloatingLabel>
            <FloatingLabel controlId="floatingSelect" label="Select your Services" className="mb-3">
                <Form.Select aria-label="Floating label select example" name='service' required>
                    <option>Select your Services</option>
                    <option>Loan Advisory</option>
                    <option>Financial Advisory</option>
                    <option>Tax Advisory</option>
                    <option>Business Advisory</option>
                    <option>IT Advisors</option>
                </Form.Select>
            </FloatingLabel>
            <FloatingLabel controlId="floatingTextarea2" label="Comments" className="mb-3">
                <Form.Control as="textarea" name='message' placeholder="Leave a comment here"style={{ height: '100px' }}/>
            </FloatingLabel>
            <button type="submit" class="contactteam btn btn-primary btn-lg" fdprocessedid="lvtf7k">Submit</button>
        </Form>
        <ToastContainer/>
     </div>
    );
  };

export default Contactform;