// import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Contactnow  from '../components/contact';
// import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Header from '../components/innerHeader'

const Loan_advisory = () => {
    return(
        <div>
        <Header/>  
        <div fluid="md" className='businessarticals'>
               <div className='grid_container'>
               <Col className='businessContainer'>
               <img class="d-block w-100" src="assets/LoanAVif.webp" alt="First slide"/>
               <Row> 
                    <div class="caption"><h5>Loan Advisory </h5><p>We Are Providing Loan Advisory for Home Loans (Housing Loans), Personal Loans, Auto Loans, Education Loans, Business Loans, Gold Loans, Loan Against Property (LAP), Personal Overdraft (OD) Facility, Loan Against Securities, Microfinance Loans, Credit Cards & Rural Loans</p>                   
                    <a href="#whatyouwant" class="contactteam btn btn-primary btn-lg">For More Info Contact Us</a></div>

                </Row>
               </Col>
                <Col className='card_container'>
                <Col className='grid-col-articals'>
                    <Card className='mb-2 card bg-light text-dark'>
                        <Card.Body>
                        <Card.Title>Home Loans (Housing Loans)</Card.Title>
                        <Card.Text>
                              Home loans are provided for purchasing or constructing a residential property.Repayment periods can extend up to several years, and interest rates may be fixed or floating.
                        </Card.Text>
                        <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
                        </Card.Body>
                    </Card>
                </Col>
           <Col className='grid-col-articals'>
                <Card className='mb-2 card bg-light text-dark'>
                    <Card.Body>
                        <Card.Title>Personal Loans</Card.Title>
                        <Card.Text>
                            Personal loans are unsecured loans that can be used for various purposes, such as medical expenses, travel,
                            or debt consolidation.The approval is based on the borrower's creditworthiness, and interest rates are generally higher compared to secured loans.
                        </Card.Text>
                      <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
                    </Card.Body>
                </Card>
                </Col>


                <Col className='grid-col-articals'>
                <Card className='mb-2 card bg-light text-dark'>
                    <Card.Body>
                        <Card.Title>Auto Loans</Card.Title>
                        <Card.Text>
                            Auto loans are offered for purchasing new or used vehicles, including cars and two-wheelers.
                            The vehicle serves as collateral, and the loan term varies based on the type of vehicle.

                        </Card.Text>
                      <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
                    </Card.Body>
                </Card>
                </Col>

                <Col className='grid-col-articals'>
                <Card className='mb-2 card bg-light text-dark'>
                    <Card.Body>
                        <Card.Title>Education Loans</Card.Title>
                        <Card.Text>
                            Education loans are designed to fund the education expenses of students, including tuition fees,
                            accommodation,and other related costs.Repayment typically starts after the completion of the education.
                        </Card.Text>
                      <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
                    </Card.Body>
                </Card>
                </Col>

                <Col className='grid-col-articals'>
                <Card className='mb-2 card bg-light text-dark'>
                    <Card.Body>
                        <Card.Title> Business Loans</Card.Title>
                        <Card.Text>
                            Business loans are provided to entrepreneurs and businesses for various purposes, such as working capital,
                            expansion, or purchasing equipment.
                            These loans may be secured or unsecured, depending on the terms.
                        </Card.Text>
                      <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
                    </Card.Body>
                </Card>
                </Col>

                <Col className='grid-col-articals'>
                <Card className='mb-2 card bg-light text-dark'>
                    <Card.Body>
                        <Card.Title> Gold Loans</Card.Title>
                        <Card.Text>
                            Gold loans involve using gold as collateral to secure a loan.
                            The loan amount is determined based on the value of the gold pledged, and interest rates may vary.
                        </Card.Text>
                      <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
                    </Card.Body>
                </Card>
                </Col>

                <Col className='grid-col-articals'>
                <Card className='mb-2 card bg-light text-dark'>
                    <Card.Body>
                        <Card.Title> Loan Against Property (LAP)</Card.Title>
                        <Card.Text>
                            LAP allows individuals to use their property (residential or commercial) as collateral for a loan.
                            The loan amount is determined based on the property's value, and interest rates are usually lower than
                            personal loans.
                        </Card.Text>
                      <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
                    </Card.Body>
                </Card>
                </Col>

                <Col className='grid-col-articals'>
                <Card className='mb-2 card bg-light text-dark'>
                    <Card.Body>
                        <Card.Title> Personal Overdraft (OD) Facility</Card.Title>
                        <Card.Text>
                            A personal overdraft facility provides a line of credit that allows individuals to withdraw funds up to a
                            specified limit.
                            Interest is charged only on the amount utilized, providing flexibility in managing finances.
                        </Card.Text>
                      <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
                    </Card.Body>
                </Card>
                </Col>

                <Col className='grid-col-articals'>
                <Card className='mb-2 card bg-light text-dark'>
                    <Card.Body>
                        <Card.Title> Two-Wheeler Loans</Card.Title>
                        <Card.Text>
                            These loans specifically cater to the purchase of two-wheelers, providing financing options for bikes and
                            scooters.
                        </Card.Text>
                      <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
                    </Card.Body>
                </Card>
                </Col>

                <Col className='grid-col-articals'>
                <Card className='mb-2 card bg-light text-dark'>
                    <Card.Body>
                        <Card.Title> Loan Against Securities</Card.Title>
                        <Card.Text>
                            Borrowers can pledge financial securities such as stocks, bonds, or mutual funds to secure a loan.
                            The loan amount is determined based on the market value of the securities.
                        </Card.Text>
                      <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
                    </Card.Body>
                </Card>
                </Col>

                <Col className='grid-col-articals'>
                <Card className='mb-2 card bg-light text-dark'>
                    <Card.Body>
                        <Card.Title> Microfinance Loans</Card.Title>
                        <Card.Text>
                            Microfinance institutions offer small loans to individuals, particularly in rural areas, to support
                            entrepreneurial activities and income generation.
                        </Card.Text>
                      <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
                    </Card.Body>
                </Card>
                </Col>

                <Col className='grid-col-articals'>
                <Card className='mb-2 card bg-light text-dark'>
                    <Card.Body>
                        <Card.Title> Government-Sponsored Loans</Card.Title>
                        <Card.Text>
                            Various government schemes provide subsidized loans for specific purposes, such as agriculture, housing, or
                            small-scale industries.
                        </Card.Text>
                      <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
                    </Card.Body>
                </Card>
                </Col>

                <Col className='grid-col-articals'>
                <Card className='mb-2 card bg-light text-dark'>
                    <Card.Body>
                        <Card.Title> Credit Cards</Card.Title>
                        <Card.Text>
                            While not traditional loans, credit cards provide a revolving line of credit that allows users to make
                            purchases and repay over time.
                        </Card.Text>
                      <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
                    </Card.Body>
                </Card>
                </Col>

                <Col className='grid-col-articals'>
                <Card className='mb-2 card bg-light text-dark'>
                    <Card.Body>
                        <Card.Title> Pradhan Mantri Mudra Yojana (PMMY) Loans</Card.Title>
                        <Card.Text>
                            This government initiative supports micro and small business enterprises by providing loans through banks
                            and financial institutions.
                        </Card.Text>
                      <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
                    </Card.Body>
                </Card>
                </Col>

                <Col className='grid-col-articals'>
                <Card className='mb-2 card bg-light text-dark'>
                    <Card.Body>
                        <Card.Title> Rural Loans</Card.Title>
                        <Card.Text>
                            Specialized loans are designed to meet the financial needs of individuals in rural areas, including
                            agricultural loans and rural housing loans.
                        </Card.Text>
                      <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
                    </Card.Body>
                </Card>
                </Col>
                </Col>
               
               </div>
               <div className='contactpage'>
               <Contactnow/>
               </div>
               </div>
            </div> 
    )
}

export default Loan_advisory;