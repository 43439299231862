import React from 'react'
import Routers from './Routers';
import Footers from './components/footer'
import './App.css';
function App() {
  return (
    <section>
        <Routers/>
        <Footers/>
    </section>
  );
}

export default App;
