

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Contactnow  from '../components/contact';
import Card from 'react-bootstrap/Card';
import Header from '../components/innerHeader'

const Business_advisory = () => {
    return(
      <div>
         <Header/>
         <div fluid="md" className='businessarticals'>
            <div className='grid_container'>
              <Col className='businessContainer'>
                  <img class="d-block w-100" src="assets/business_advisory.webp" alt="First slide"/>
                  <Row> 
                    <div class="caption"><h5>Business Advisory </h5><p>We Navigate Your Business with Expert Advisory,Crafting a Successful Business Strategy for Growth,Effective Financial Management Tips for Small Businesses,
"Cash Flow Management Strategies</p></div>
<a href="#whatyouwant" class="contactteam btn btn-primary btn-lg">For More Info Contact Us</a>
                  </Row>
               </Col>

               <Col className='card_container'>
               <Col className='grid-col-articals'>
        <Card className='mb-2 card bg-light text-dark'>
            <Card.Body>
                <Card.Title> Strategic Planning</Card.Title>
                <Card.Text>

                "Crafting a Successful Business Strategy for Growth"
                "The Importance of Strategic Planning in Business"
                "SWOT Analysis: A Key Tool for Business Strategy"
                </Card.Text>

                <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
            </Card.Body>
        </Card>
        </Col>
        <Col className='grid-col-articals'>
        <Card className='mb-2 card bg-light text-dark'>
            <Card.Body>
                <Card.Title> Marketing and Branding</Card.Title>
                <Card.Text>
"Crafting a Strong Brand Identity for Your Business"
"Digital Marketing Strategies for Small Businesses"
"Building a Successful Social Media Marketing Plan"  </Card.Text>
              <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
            </Card.Body>
        </Card>
               </Col>
        <Col className='grid-col-articals'>
        <Card className='mb-2 card bg-light text-dark'>
            <Card.Body>
                <Card.Title>Investment Strategies</Card.Title>
                <Card.Text>

                    "Diversifying Your Investment Portfolio for Long-Term Growth"
                    "Smart Ways to Invest in a Volatile Market"
                    "Introduction to Mutual Funds and ETFs"
                </Card.Text>
              <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
            </Card.Body>
        </Card>
        </Col>

        <Col className='grid-col-articals'>
        <Card className='mb-2 card bg-light text-dark'>
            <Card.Body>
                <Card.Title>Retirement Planning</Card.Title>
                <Card.Text>

                    "Building a Solid Retirement Savings Plan"
                    "Choosing the Right Retirement Accounts: 401(k), IRA, and More"
                    "Strategies for a Comfortable Retirement Lifestyle"
                </Card.Text>
              <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
            </Card.Body>
        </Card>
        </Col>

        <Col className='grid-col-articals'>
        <Card className='mb-2 card bg-light text-dark'>
            <Card.Body>
                <Card.Title> Debt Management</Card.Title>
                <Card.Text>

                    "Effective Ways to Pay Off Credit Card Debt"
                    "Understanding Debt Consolidation Options"
                    "Tips for Managing Student Loan Debt"
                </Card.Text>
              <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
            </Card.Body>
        </Card>
        </Col>

        <Col className='grid-col-articals'>
        <Card className='mb-2 card bg-light text-dark'>
            <Card.Body>
                <Card.Title> Real Estate and Mortgages</Card.Title>
                <Card.Text>

                    "Guide to Buying Your First Home"
                    "How to Get the Best Mortgage Rates"
                    "Investing in Real Estate: Pros and Cons"
                </Card.Text>
              <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
            </Card.Body>
        </Card>
        </Col>

        <Col className='grid-col-articals'>
        <Card className='mb-2 card bg-light text-dark'>
            <Card.Body>
                <Card.Title> Tax Planning</Card.Title>
                <Card.Text>

                    "Year-Round Tax Planning Tips for Individuals"
                    "Tax-Efficient Investing Strategies"
                    "Common Tax Deductions You Shouldn't Miss"
                </Card.Text>
              <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
            </Card.Body>
        </Card>
        </Col>

        <Col className='grid-col-articals'>
        <Card className='mb-2 card bg-light text-dark'>
            <Card.Body>
                <Card.Title> Financial Planning for Life Events</Card.Title>
                <Card.Text>

                    "Financial Planning for a Wedding"
                    "Preparing for Parenthood: A Financial Guide"
                    "Navigating Financial Challenges After Divorce"
                </Card.Text>
              <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
            </Card.Body>
        </Card>
        </Col>

        <Col className='grid-col-articals'>
        <Card className='mb-2 card bg-light text-dark'>
            <Card.Body>
                <Card.Title> Insurance Guidance</Card.Title>
                <Card.Text>

                    "Choosing the Right Insurance Coverage for Your Needs"
                    "Understanding Health Insurance Options"
                    "The Importance of Life Insurance and Estate Planning"

                    </Card.Text>
              <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
            </Card.Body>
        </Card>
        </Col>
        <Col className='grid-col-articals'>
        <Card className='mb-2 card bg-light text-dark'>
            <Card.Body>
                    <Card.Title>   Small Business and Entrepreneurial Finance</Card.Title>
                    <Card.Text>

                        "Financial Tips for Small Business Owners"
                        "How to Secure Funding for Your Startup"
                        "Navigating Finances in the Gig Economy"
                    </Card.Text>
              <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
            </Card.Body>
        </Card>
        </Col>

        <Col className='grid-col-articals'>
        <Card className='mb-2 card bg-light text-dark'>
            <Card.Body>
                <Card.Title> Economic Trends and Market Updates</Card.Title>
                <Card.Text>

                    "The Impact of Economic Trends on Personal Finances"
                    "Quarterly Market Updates and Insights"
                    "How Global Events Affect Your Investments"
                </Card.Text>
              <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
            </Card.Body>
        </Card>
        </Col>

        <Col className='grid-col-articals'>
        <Card className='mb-2 card bg-light text-dark'>
            <Card.Body>
                <Card.Title> Financial Literacy Campaigns</Card.Title>
                <Card.Text>

                    "Promoting Financial Literacy in Your Community"
                    "The Role of Financial Education in Schools"
                    "Online Resources for Improving Financial Literacy"
                </Card.Text>
              <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
            </Card.Body>
        </Card>
        </Col>

        <Col className='grid-col-articals'>
        <Card className='mb-2 card bg-light text-dark'>
            <Card.Body>
                <Card.Title> Interactive Tools and Calculators</Card.Title>
                <Card.Text>

                    Develop tools like budget calculators, retirement planning calculators, or investment risk
                    assessment tools to engage users.
                </Card.Text>
              <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
            </Card.Body>
        </Card>
        </Col>

        <Col className='grid-col-articals'>
        <Card className='mb-2 card bg-light text-dark'>
            <Card.Body>
                <Card.Title> Client Success Stories/Testimonials</Card.Title>
                <Card.Text>

                    Share success stories of clients who have followed your advice and achieved their financial goals.
                </Card.Text>
              <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
            </Card.Body>
        </Card>
        </Col>

        <Col className='grid-col-articals'>
        <Card className='mb-2 card bg-light text-dark'>
            <Card.Body>
                <Card.Title> Podcasts and Webinars</Card.Title>
                <Card.Text>

                    Create audio or video content discussing financial topics and inviting experts for interviews.
                </Card.Text>
              <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
            </Card.Body>
        </Card>
        </Col>

       
               </Col>
            </div>

            <div className='contactpage'>
               <Contactnow/>
               </div>
             
        </div>
      </div>
        
    )
}

export default Business_advisory;