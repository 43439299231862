import React from 'react';
import Banner from '../components/bannercarousel'
import Aboutus  from '../components/about'
import Cards  from '../components/services'
import News  from '../components/news'
import Contact  from '../components/contact'
import Header from '../components/navbar'
import Fxtrade from '../components/fxtradenav';

const Homepage = () => {
    return(
       <div>
         <Header/>
         <Banner/>
        <Aboutus/>
        <Cards/>
        <Contact/>
        <News/>
        <Fxtrade/>
       </div>
       
    )
}

export default Homepage;