import { useState } from 'react';
// import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';

function Fxtradenav() {
    const [open, setOpen] = useState(true);
  return (
    <Collapse in={open}>
<Navbar  sticky="bottom" expand="lg" className="bg-body-tertiary fx_container">
    <span className='btn-close targetclose Dskhide' onClick={() => setOpen(!open)} aria-controls="collapse" aria-expanded={open}></span>
      <Container id='fxcontainer'>
        <Navbar.Brand href="https://fxconnect.io/hpage/image/fx7.png"><Image src="https://fxconnect.io/hpage/image/fx7.png" alt="fxconnect" class="light-version-logo"/></Navbar.Brand>
        <Nav.Link href="https://trade.fxconnect.io/register/?ref=2RNUXL">
             <h5>World class Trading Platforms, Enjoy Tech. Enjoy Investing.</h5>
             <p>Fxconnect's super-fast flagship trading platform with elegant UI, streaming market data, advanced charts.Create Your Forex Trading Account Our Fx Connect Get Instant 20% Real Mony Bonus For Yours Initial Margin Fund (One Time)</p>
             <address>FX Connect Limited, 2ed Floor College House, 17 King Edwards Road,  London UK, Code : Ha4 7ae</address>
        </Nav.Link>
        <Nav.Link href="https://trade.fxconnect.io/register/?ref=2RNUXL">
               <button className='btn btn-primary'>Login</button>
        </Nav.Link>
      </Container>
    </Navbar>
    </Collapse>
    
  );
}

export default Fxtradenav;