// import logo from './logo.svg';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from "react-router-dom";

// import NavDropdown from 'react-bootstrap/NavDropdown';
// import { Link, animateScroll as scroll } from "react-scroll";
// import { useLocation } from "react-router-dom";

function Header() {
  
  return (
        <Navbar  bg="light" expand="lg" fixed="top" collapseOnSelect className="bg-body-tertiary">
    <Container fluid>
    <Navbar.Brand href="/">
            <img
              src="assets/Logo.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
      </Navbar.Brand>
      <Navbar.Toggle  aria-controls="navbarScroll"/>
      <Navbar.Collapse  id="navbarScroll">
        <Nav className="me-auto my-2 my-lg-0" navbarScroll>
        <NavLink  className="nav-link" to="/">Home</NavLink>
        <NavLink  className="nav-link" to="/loan_advisory">Loan Advisory</NavLink>
        <NavLink  className="nav-link" to="/financial_advisory">Financial Advisory </NavLink>
        <NavLink  className="nav-link" to="/tax_advisory">Tax Advisory </NavLink>
        <NavLink  className="nav-link" to="/business_advisory">Business Advisory </NavLink>
        <NavLink  className="nav-link" to="/IT_advisory">IT Advisory </NavLink>
          <NavLink  className="nav-link" to="/contact">Contact us</NavLink>
          <Nav.Link href="mailto:santebusinesssolutions@gmail.com" className='font-weight-bold infocontact'>
            <span className='btn btn-instagram btn-light btn-icon'>
            <svg className="ft-icon" id="Capa_1" enableBackground="new 0 0 479.058 479.058" height="512" viewBox="0 0 479.058 479.058" width="512" xmlns="http://www.w3.org/2000/svg">
            <path fill="#000" d="m434.146 59.882h-389.234c-24.766 0-44.912 20.146-44.912 44.912v269.47c0 24.766 20.146 44.912 44.912 44.912h389.234c24.766 0 44.912-20.146 44.912-44.912v-269.47c0-24.766-20.146-44.912-44.912-44.912zm0 29.941c2.034 0 3.969.422 5.738 1.159l-200.355 173.649-200.356-173.649c1.769-.736 3.704-1.159 5.738-1.159zm0 299.411h-389.234c-8.26 0-14.971-6.71-14.971-14.971v-251.648l199.778 173.141c2.822 2.441 6.316 3.655 9.81 3.655s6.988-1.213 9.81-3.655l199.778-173.141v251.649c-.001 8.26-6.711 14.97-14.971 14.97z"></path>
            </svg>
            </span>santebusinesssolutions@gmail.com
            </Nav.Link>
          <Nav.Link className='font-weight-bold infocontact' href="tel:9363353206">
          <span className='btn btn-instagram btn-light btn-icon'>
          <svg  className="ft-icon" enableBackground="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg">
            <g><path fill="#000" d="m361 0h-210c-33.084 0-60 26.916-60 60v392c0 33.084 26.916 60 60 60h210c33.084 0 60-26.916 60-60v-392c0-33.084-26.916-60-60-60zm-69.271 30-15 30h-41.459l-15-30zm69.271 452h-210c-16.542 0-30-13.458-30-30v-8.072c8.833 5.123 19.075 8.072 30 8.072h210c10.925 0 21.167-2.949 30-8.072v8.072c0 16.542-13.458 30-30 30zm0-60h-210c-16.542 0-30-13.458-30-30v-332c0-16.542 13.458-30 30-30h35.729l30 60h78.541l30-60h35.73c16.542 0 30 13.458 30 30v332c0 16.542-13.458 30-30 30z"></path></g>
            </svg>
          </span>+91-9363353206</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
  );
}

export default Header;
