import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Contactnow  from '../components/contact';
import Card from 'react-bootstrap/Card';
import Header from '../components/innerHeader'

const IT_advisory = () => {
    return(
        <div>
        <Header/>  
        <div fluid="md" className='businessarticals'>
            <div className='grid_container'>
              <Col className='businessContainer'>
                  <img class="d-block w-100" src="assets/ITadvisory.webp" alt="First slide"/>
                  <Row> 
                    <div class="caption"><h5>IT Advisory </h5><p>Our services are aimed at articulating your company's problems and needs, offering and assessing solutions Through Expert Advisory</p></div>
                        <a href="#whatyouwant" class="contactteam btn btn-primary btn-lg">For More Info Contact Us</a>
                  </Row>
               </Col>
            </div>
            <Col className='card_container'>
            <Col className='grid-col-articals'>
<Card className='mb-2 card bg-light text-dark'>
    <Card.Body>
        <Card.Title>IT Strategy and Planning</Card.Title>
        <Card.Text>
            "Crafting a Strategic IT Plan for Business Success"
            "Aligning IT Strategy with Organizational Goals"
            "IT Budgeting and Resource Allocation: Advisory Insights"
        </Card.Text>
        <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
    </Card.Body>
</Card>
</Col>

<Col className='grid-col-articals'>
<Card className='mb-2 card bg-light text-dark'>
    <Card.Body>
        <Card.Title>Cloud Computing</Card.Title>
        <Card.Text>
            "Leveraging Cloud Solutions for Business Agility"
            "Cloud Security Best Practices: IT Advisory Perspectives"
            "Migration to the Cloud: IT Advisory Considerations"
        </Card.Text>
        <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
    </Card.Body>
</Card>
</Col>

<Col className='grid-col-articals'>
<Card className='mb-2 card bg-light text-dark'>
    <Card.Body>
        <Card.Title>Data Governance and Management</Card.Title>
        <Card.Text>
            "Exploring the Impact of AI and Machine Learning: An IT Advisory Outlook"
            "Blockchain in Business: IT Advisory Strategies"
            "IoT Implementation: IT Advisory Considerations"
        </Card.Text>
        <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
    </Card.Body>
</Card>
</Col>

<Col className='grid-col-articals'>
<Card className='mb-2 card bg-light text-dark'>
    <Card.Body>
        <Card.Title>IT Risk Management</Card.Title>
        <Card.Text>
            "Identifying and Mitigating IT Risks: A Practical Advisory Guide"
            "Disaster Recovery and Business Continuity Planning"
            "Security Incident Response Planning: IT Advisory Insights"

        </Card.Text>
        <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
    </Card.Body>
</Card>
</Col>

<Col className='grid-col-articals'>
<Card className='mb-2 card bg-light text-dark'>
    <Card.Body>
        <Card.Title>Agile and DevOps</Card.Title>
        <Card.Text>
            "Implementing Agile Methodologies in IT: Advisory Best Practices"
            "DevOps for Business Efficiency: IT Advisory Strategies"
            "Continuous Integration and Continuous Deployment (CI/CD): IT Advisory Insights"
        </Card.Text>
        <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
    </Card.Body>
</Card>
</Col>

<Col className='grid-col-articals'>
<Card className='mb-2 card bg-light text-dark'>
    <Card.Body>
        <Card.Title>User Experience (UX) and Design</Card.Title>
        <Card.Text>
            "Integrating UX Design into IT Projects: Advisory Approaches"
            "Enhancing User Engagement through IT Advisory in Design"
            "Accessibility in IT: A UX Perspective"
        </Card.Text>
        <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
    </Card.Body>
</Card>
</Col>

<Col className='grid-col-articals'>
<Card className='mb-2 card bg-light text-dark'>
    <Card.Body>
        <Card.Title>IT Project Management</Card.Title>
        <Card.Text>
            "Effective Project Management in IT: Advisory Principles"
            "Risk Management in IT Projects: Advisory Strategies"
            "Agile vs. Waterfall: Choosing the Right Approach"
        </Card.Text>
        <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
    </Card.Body>
</Card>
</Col>

<Col className='grid-col-articals'>
<Card className='mb-2 card bg-light text-dark'>
    <Card.Body>
        <Card.Title>IT Outsourcing and Vendor Management</Card.Title>
        <Card.Text>
            "Strategies for Successful IT Outsourcing: Advisory Perspectives"
            "Effective Vendor Management in IT Operations"
            "Negotiating IT Service Level Agreements (SLAs): Advisory Tips"
        </Card.Text>
        <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
    </Card.Body>
</Card>
</Col>

<Col className='grid-col-articals'>
<Card className='mb-2 card bg-light text-dark'>
    <Card.Body>
        <Card.Title>Remote Work and IT Advisory</Card.Title>
        <Card.Text>
            "Adapting IT Infrastructure for Remote Work: Advisory Insights"
            "Ensuring Cybersecurity in Remote Work Environments"
            "Collaboration Tools and Technologies: IT Advisory Recommendations"
        </Card.Text>
        <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
    </Card.Body>
</Card>
</Col>

<Col className='grid-col-articals'>
<Card className='mb-2 card bg-light text-dark'>
    <Card.Body>
        <Card.Title>Industry-Specific IT Advisory</Card.Title>
        <Card.Text>
            "IT Advisory for Healthcare: Navigating Regulatory Compliance"
            "IT Advisory in Finance: Addressing Security and Compliance Challenges"
            "Retail Technology Trends: IT Advisory Considerations"
        </Card.Text>
        <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
    </Card.Body>
</Card>
</Col>
            </Col>
            <div className='contactpage'>
               <Contactnow/>
            </div>
        </div>
        </div>
    )
}

export default IT_advisory;