// import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useEffect, useState} from 'react';
import Image from 'react-bootstrap/Image';
// 
// import {useEffect, useState} from 'react';
// import NewsAPI from "newsapi";
// import qs from 'qs'

const Fetch = () => {
  // const [articles, setArticles] = useState([]);
  // useEffect(() => {
  //   fetch('https://newsapi.org/v2/everything?q=bitcoin&from=2023-12-20&sortBy=publishedAt&apiKey=9d6c559f272c4d5ea3c67e0d1027ae6e')
  //     .then((res) => {
  //       return res.json();
  //     })
  //     .then((data) => {
  //       console.log(data);
  //       setArticles(data);
  //     });
  // }, []);

  const [articles, setArticles] = useState([])

  const fetchArticlesData = () => {
    fetch("https://newsdata.io/api/1/news?apikey=pub_369596ae2c939af54bec4d8ea4d27cf91e3d3&q=news&country=in&language=en&category=business")
      .then(response => {
        return response.json()
      })
      .then(data => {
        setArticles(data.results)
        console.log(data.results)
      })
  }

  useEffect(() => {
    fetchArticlesData()
  }, [])
  return (
      <div className='team section' id='news'>

<Container>
    <Row>
    <div class="caption"><h5>News</h5><p>Top New's Updates On Business, Finance, Economy, Stock Market, BSE, NSE, Nifty, Sensex & Much More .</p></div>
     

      {articles.length >= 0 && (
        <Col>
          {articles.filter((key, index) => index <= 5).map(data => (
    
           <Card>     
             <span className='testimonial'>
             {articles.image_url ? <span className='nill'>Empty image</span>:<Image src={data.image_url} alt={data.title}/>}
                
             </span>
           <Card.Body>
               <Card.Title>{data.source_id}<b>{data.title}</b></Card.Title>
                   <Card.Text>

                   {data.description <= 100
                ? `${data.description}`
                : `${data.description.substring(0, 96)}...`}
                   </Card.Text>
               <a variant="primary" className='btn btn-primary' href={data.link}>Read More...</a>
           </Card.Body>
           </Card>
          ))}
        </Col>
      )}
     
    
    </Row>
    <a href="/contact" type="button" className="contactteam btn btn-primary btn-lg" fdprocessedid="sl2v2b">For More Info Contact Us</a>
  </Container>

  </div>

  );
};
export default Fetch;


// function News() {
//   return (
//     <div className='team section'>

//   <Container>
//       <Row>
//       <div class="caption"><h5>News</h5><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p></div>
//         <Col>
//         <Card style={{ width: '18rem' }}>
        
//              <Card.Body>
//                 <Card.Title>Saul Goodman <b>Designer</b></Card.Title>
//                     <Card.Text>
//                         Some quick example text to build on the Saul Goodman and make up the
//                         bulk of the card's content.
//                     </Card.Text>
//                 <Button variant="primary">Go somewhere</Button>
//             </Card.Body>
//         </Card>
//         </Col>
//         <Col>
//         <Card style={{ width: '18rem' }}>
        
//              <Card.Body>
//                 <Card.Title>Saul Goodman <b>Designer</b></Card.Title>
//                     <Card.Text>
//                         Some quick example text to build on the Saul Goodman and make up the
//                         bulk of the card's content.
//                     </Card.Text>
//                 <Button variant="primary">Go somewhere</Button>
//             </Card.Body>
//         </Card>
//         </Col>
//         <Col>
//         <Card style={{ width: '18rem' }}>
        
//              <Card.Body>
//                 <Card.Title>Saul Goodman <b>Designer</b></Card.Title>
//                     <Card.Text>
//                         Some quick example text to build on the Saul Goodman and make up the
//                         bulk of the card's content.
//                     </Card.Text>
//                 <Button variant="primary">Go somewhere</Button>
//             </Card.Body>
//         </Card>
//         </Col>
//         <Col>
//         <Card style={{ width: '18rem' }}>
             
//              <Card.Body>
//                 <Card.Title>Saul Goodman <b>Designer</b></Card.Title>
//                     <Card.Text>
//                         Some quick example text to build on the Saul Goodman and make up the
//                         bulk of the card's content.
//                     </Card.Text>
//                 <Button variant="primary">Go somewhere</Button>
//             </Card.Body>
//         </Card>
//         </Col>
//       </Row>
//     </Container>
    
//     </div>
//   );
// }

// export default News;