import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Aboutus() {
  return (

    <div className='aboutus section' id='whyus'>
     <Container fluid="md">
      <Row>
      <div class="caption"><h5>Why Us ?</h5><p>Need help with Loan, Finance, Taxes, Business & Growth? You're at the right place.</p></div>
        <Col>
            <h6>
            We are a team of seasoned professionals, including financial experts, tax specialists, and business strategists, united by a passion for helping our clients thrive. With years of experience in the industry, we bring a wealth of knowledge and a commitment to staying at the forefront of evolving financial landscapes.
           At santesolutions our mission is to demystify the world of finance and taxes, making it accessible to everyone. We believe that financial empowerment is the key to unlocking a brighter future, and we are dedicated to providing expert guidance and support to help you make informed decisions.
            </h6>

            <button type="button" class="contactteam btn btn-primary btn-lg">For More Info Contact Us</button>
        </Col>
      </Row>
    </Container> 
    </div>
    
  );
}

export default Aboutus;