import Carousel from 'react-bootstrap/Carousel';
// import Button from 'react-bootstrap/Button';

function Banner() {
  return (

    <Carousel>
       <Carousel.Item>
        <img
          className="d-block w-100 slide"
          src="assets/Loan.webp"
          alt="First slide"
        />
        <Carousel.Caption>
          <h5 className='h5 '>Loan Advisory</h5>
          <p>We Are Providing Loan Advisory for Home Loans (Housing Loans), Personal Loans, Auto Loans, Education Loans, Business Loans, Gold Loans, Loan Against Property (LAP), Personal Overdraft (OD) Facility, Loan Against Securities, Microfinance Loans, Credit Cards & Rural Loans.</p>
          <a href="#whatyouwant" class="contactteam btn btn-primary btn-lg" size="lg">For More Info Contact Us</a>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100 slide1"
          src="assets/Banner_1.webp"
          alt="First slide"
        />
        <Carousel.Caption>
          <h5 className='h5 '>Grow Your Business</h5>
          <p>Growing a business requires many things to be right. We take care of these and enable the growth that is core to your business.</p>
          <a href="#whatyouwant" class="contactteam btn btn-primary btn-lg" size="lg">For More Info Contact Us</a>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 slide2"
          src="assets/Banner_2.webp"
          alt="Second slide"
        />
        <Carousel.Caption>
          <h5>Stress-Free Accounts & Taxes</h5>
          <p>Experience the peace of mind We take care of Taxes, Audit & Accounting</p>
          <a href="#whatyouwant" class="contactteam btn btn-primary btn-lg" size="lg">For More Info Contact Us</a>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 slide3"
          src="assets/Banner_3.webp"
          alt="Third slide"
        />
        <Carousel.Caption>
          <h5>Maximize Your Profits</h5>
          <p>
          Forget cash flow problems and make a higher profits with us we helps you to automate & save costs, optimize your inventory, produce higher & faster & improve margins.
          </p>
          <a href="#whatyouwant" class="contactteam btn btn-primary btn-lg" size="lg">For More Info Contact Us</a>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100 slide4"
          src="assets/Banner_4.webp"
          alt="Third slide"
        />
        <Carousel.Caption>
          <h5>Gain Time & Enjoy Business</h5>
          <p>
          Finance, operations, processes, technology we take care of it all giving you the time to focus on sales & growth.
          </p>
          <a href="#whatyouwant" class="contactteam btn btn-primary btn-lg" size="lg">For More Info Contact Us</a>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100 slide5"
          src="assets/Banner_5.webp"
          alt="Third slide"
        />
        <Carousel.Caption>
          <h5>Small Business Solutions</h5>
          <p>
          Experience the peace of mind that we take care of Tax, Consulting & Audit Solutions specially tailored to small businesses.
          </p>
          <a href="#whatyouwant" class="contactteam btn btn-primary btn-lg" size="lg">For More Info Contact Us</a>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default Banner;