// import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';

function Servicescard() {
  return (
    <div className='outcard section' id='whatwedo'>

  <Container>
      <div className="caption"><h5>What we Do ?</h5><p>We Are Providing Top Services With Excellent Performance.</p></div>
        <Col className='card12'>
        <Card>
            <Card.Img variant="top" alt='services' src="assets/6.webp" />
             <Card.Body>
                <Card.Title>Loan Advisory <Card.Text>We Navigate Your Loan Journey with Expert Advisory</Card.Text></Card.Title>
                    
                    <ListGroup variant="flush">

                            <ListGroup.Item><a href="/loan_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>  Home loans</a></ListGroup.Item>
                            <ListGroup.Item><a href="/loan_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   Medical loans</a></ListGroup.Item>
                            <ListGroup.Item><a href="/loan_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   Personal loans</a></ListGroup.Item>
                            <ListGroup.Item><a href="/loan_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   Education loans</a></ListGroup.Item>
                            <ListGroup.Item><a href="/loan_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   Wedding loans .etc</a></ListGroup.Item>
                        </ListGroup>
                    
                <a href="/loan_advisory" class="btn btn-primary">Read More..</a>
            </Card.Body>
        </Card>
        </Col>
        <Col className='card12'>
        <Card>
            <Card.Img variant="top" alt='services' src="assets/1.webp" />
             <Card.Body>
                <Card.Title>Financial Advisory <Card.Text>We build the Path to Your Financial Excellence</Card.Text></Card.Title>
                

                    <ListGroup variant="flush">
                    <ListGroup.Item><a href="/financial_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   Financial planning</a> </ListGroup.Item>
                    <ListGroup.Item><a href="/financial_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   Retirement planning</a> </ListGroup.Item>
                    <ListGroup.Item><a href="/financial_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   Financial Consultant</a> </ListGroup.Item>
                    <ListGroup.Item><a href="/financial_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   Investment advisors</a> </ListGroup.Item>
                    <ListGroup.Item><a href="/financial_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   Tax planning etc.</a> </ListGroup.Item>
                    </ListGroup>
                <a href="/financial_advisory" class="btn btn-primary">Read More..</a>
            </Card.Body>
        </Card>
        </Col>
        <Col className='card12'>
        <Card>
            <Card.Img variant="top" alt='services' src="assets/3.webp" />
             <Card.Body>
                <Card.Title>Tax Advisory <Card.Text>We Guiding Your Tax Through Expert Advisory</Card.Text></Card.Title>
                    

                    <ListGroup variant="flush">
                        <ListGroup.Item><a href="/tax_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   Income tax planning & filing</a> </ListGroup.Item>
                        <ListGroup.Item><a href="/tax_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   Personal tax </a> </ListGroup.Item>
                     
                        <ListGroup.Item><a href="/tax_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   Corporate tax</a> </ListGroup.Item>
                        <ListGroup.Item><a href="/tax_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   NRI & foreign company advisory</a> </ListGroup.Item>
                        <ListGroup.Item><a href="/tax_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   GST,insurance,mutual fund etc.</a> </ListGroup.Item>
                    </ListGroup>
                <a href="/tax_advisory" class="btn btn-primary">Read More..</a>
            </Card.Body>
        </Card>
        </Col>
        <Col className='card12'>
        <Card>
            <Card.Img variant="top" alt='services' src="assets/4.webp" />
             <Card.Body>
                <Card.Title>Business Advisory <Card.Text>We Navigate Your Business with Expert Advisory</Card.Text></Card.Title>
                    

                    <ListGroup variant="flush">
                        <ListGroup.Item><a href="/business_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   Growth strategist</a> </ListGroup.Item>
                        <ListGroup.Item><a href="/business_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   Cashflow Management </a> </ListGroup.Item>
                        <ListGroup.Item><a href="/business_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   Financing</a> </ListGroup.Item>
                        <ListGroup.Item><a href="/business_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   Startup Business Advisory</a> </ListGroup.Item>
                        <ListGroup.Item><a href="/business_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   IT Advisory etc.</a> </ListGroup.Item>
                    </ListGroup>
                <a href="/business_advisory" class="btn btn-primary">Read More..</a>
            </Card.Body>
        </Card>

      
      
        </Col>
        <a href="/contact" type="button" className="contactteam btn btn-primary btn-lg" fdprocessedid="sl2v2b">For More Info Contact Us</a>
    </Container>
    
    </div>
  );
}

export default Servicescard;