import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Contactnow  from '../components/contact';
import Card from 'react-bootstrap/Card';
import Header from '../components/innerHeader'

const Finances_advisory = () => {
    return(
        <div>
        <Header/>  
        <div fluid="md" className='businessarticals'>
            <div className='grid_container'>
            <Col className='businessContainer'>
                  <img class="d-block w-100" src="assets/financial_advisory.webp" alt="First slide"/>
                  <Row> 
                    <div class="caption"><h5>Financial Advisory </h5><p>We build the Path to Your Financial Excellence,Our advisor will be in constant touch, we assure you single window assistance to satisfactory fulfilling of your loan quest.</p></div>
                        <a href="#whatyouwant" class="contactteam btn btn-primary btn-lg">For More Info Contact Us</a>
                  </Row>
               </Col>

            </div>
            <Col className='card_container'>
            <Col className='grid-col-articals'>
<Card className='mb-2 card bg-light text-dark'>
    <Card.Body>
        <Card.Title>Investment Strategies</Card.Title>
        <Card.Text>

            "Building a Diversified Investment Portfolio"
            "Risk Tolerance and Investment Planning"
            "Comparing Different Investment Vehicles: Stocks, Bonds, and Mutual Funds"
        </Card.Text>
        <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
    </Card.Body>
</Card>
</Col>


<Col className='grid-col-articals'>
<Card className='mb-2 card bg-light text-dark'>
    <Card.Body>
        <Card.Title>Retirement Planning</Card.Title>
        <Card.Text>

            "Crafting a Solid Retirement Savings Plan"
            "401(k) and IRA: A Guide to Retirement Accounts"
            "Strategies for Catching Up on Retirement Savings"
        </Card.Text>
        <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
    </Card.Body>
</Card>
</Col>


<Col className='grid-col-articals'>
<Card className='mb-2 card bg-light text-dark'>
    <Card.Body>
        <Card.Title>Tax Planning</Card.Title>
        <Card.Text>

            "Year-Round Tax Planning Tips"
            "Tax-Efficient Investing Strategies"
            "Common Tax Deductions and Credits You Should Know"
        </Card.Text>
        <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
    </Card.Body>
</Card>
</Col>


<Col className='grid-col-articals'>
<Card className='mb-2 card bg-light text-dark'>
    <Card.Body>
        <Card.Title>Debt Management</Card.Title>
        <Card.Text>

            "Effective Strategies for Paying Off Debt"
            "Consolidating Student Loans: Pros and Cons"
            "Managing Credit Card Debt: Tips for Success"
        </Card.Text>
        <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
    </Card.Body>
</Card>
</Col>

<Col className='grid-col-articals'>
<Card className='mb-2 card bg-light text-dark'>
    <Card.Body>
        <Card.Title>Estate Planning</Card.Title>
        <Card.Text>

            "The Importance of Estate Planning"
            "Creating a Will and Trust: A Comprehensive Guide"
            "Navigating Inheritance Tax: What You Need to Know"
        </Card.Text>
        <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
    </Card.Body>
</Card>
</Col>

<Col className='grid-col-articals'>
<Card className='mb-2 card bg-light text-dark'>
    <Card.Body>
        <Card.Title>Insurance Guidance</Card.Title>
        <Card.Text>
            "Choosing the Right Insurance Coverage for Your Needs"
            "Understanding Health Insurance Options"
            "Life Insurance: Types and Considerations"
        </Card.Text>
        <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
    </Card.Body>
</Card>
</Col>

<Col className='grid-col-articals'>
<Card className='mb-2 card bg-light text-dark'>
    <Card.Body>
        <Card.Title>Financial Goal Setting</Card.Title>
        <Card.Text>
            "Setting SMART Financial Goals"
            "Financial Planning for Short, Medium, and Long-Term Goals"
            "Tracking and Adjusting Your Financial Goals"
        </Card.Text>
        <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
    </Card.Body>
</Card>
</Col>

<Col className='grid-col-articals'>
<Card className='mb-2 card bg-light text-dark'>
    <Card.Body>
        <Card.Title>Market Insights</Card.Title>
        <Card.Text>

            "Understanding Economic Trends and Their Impact on Your Finances"
            "Investing in a Volatile Market: Dos and Don'ts"
            "Market Updates and Analysis"
        </Card.Text>
        <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
    </Card.Body>
</Card>
</Col>

<Col className='grid-col-articals'>
<Card className='mb-2 card bg-light text-dark'>
    <Card.Body>
        <Card.Title>Financial Literacy Campaigns</Card.Title>
        <Card.Text>

            "Promoting Financial Literacy in Your Community"
            "The Importance of Teaching Financial Literacy in Schools"
            "Online Resources for Improving Financial Literacy"
        </Card.Text>
        <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
    </Card.Body>
</Card>
</Col>
<Col className='grid-col-articals'>
<Card className='mb-2 card bg-light text-dark'>
    <Card.Body>
        <Card.Title>Credit Score and Credit Management</Card.Title>
        <Card.Text>

            "The Role of Credit Scores in Your Financial Life"
            "Improving Your Credit Score: Practical Tips"
            "How to Dispute Errors on Your Credit Report"
        </Card.Text>
        <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
    </Card.Body>
</Card>
</Col>

<Col className='grid-col-articals'>
<Card className='mb-2 card bg-light text-dark'>
    <Card.Body>
        <Card.Title>Budgeting for Life Events</Card.Title>
        <Card.Text>

            "Budgeting for a Wedding: Tips and Tricks"
            "Budgeting for Parenthood: A Financial Guide"
            "Financial Planning for a Home Purchase"
        </Card.Text>
        <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
    </Card.Body>
</Card>
</Col>
<Col className='grid-col-articals'>
<Card className='mb-2 card bg-light text-dark'>
    <Card.Body>
        <Card.Title>Savings Strategies</Card.Title>
        <Card.Text>

            "Building an Emergency Fund: Why It's Essential"
            "Smart Strategies for Saving Money Every Month"
            "Automating Your Savings: A Practical Guide"
        </Card.Text>
        <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
    </Card.Body>
</Card>
</Col>

<Col className='grid-col-articals'>
<Card className='mb-2 card bg-light text-dark'>
    <Card.Body>
        <Card.Title>Real Estate and Mortgages</Card.Title>
        <Card.Text>
            "Navigating the Homebuying Process: A Comprehensive Guide"
            "Understanding Mortgage Options and Rates"
            "Investing in Real Estate: Pros and Cons"
        </Card.Text>
        <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
    </Card.Body>
</Card>
</Col>

<Col className='grid-col-articals'>
<Card className='mb-2 card bg-light text-dark'>
    <Card.Body>
        <Card.Title>Client Success Stories/Testimonials</Card.Title>
        <Card.Text>
            Share success stories of clients who have achieved their financial goals with your guidance.
        </Card.Text>
        <a href="#whatyouwant" class="btn btn-primary">Get Quotes</a>
    </Card.Body>
</Card>
</Col>
            </Col>
            <div className='contactpage'>
               <Contactnow/>
            </div>
        </div>
        </div>
    )
}

export default Finances_advisory;