
import Contactnow  from '../components/contact';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from '../components/innerHeader';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

const Contact = () => {
    return(
        <div>
        <Header/>  
        <div className='contactpage'>
             <div fluid="md" className='contactarticals'>
               <Container>
               <Row>
                <div class="caption"><h1>Contact Us </h1><p>We Are Providing Loan Advisory, Financial Advisory, Tax Advisory, Business Advisory & IT Advisors.</p></div>
                    <Col>
                        <h6>
                        We are a team of seasoned professionals, including financial experts, tax specialists, and business strategists, united by a passion for helping our clients thrive. With years of experience in the industry, we bring a wealth of knowledge and a commitment to staying at the forefront of evolving financial landscapes.
                    At santesolutions our mission is to demystify the world of finance and taxes, making it accessible to everyone. We believe that financial empowerment is the key to unlocking a brighter future, and we are dedicated to providing expert guidance and support to help you make informed decisions.
                        </h6>

                        {/* <button type="button" class="contactteam btn btn-primary btn-lg">For More Info Contact Us</button> */}
                    </Col>
                    </Row>
                    
                    <div class="caption"><h5>Our Top Services.</h5></div>  
        <Col className='card12'>
        <Card>
            <Card.Img variant="top" alt='services' src="assets/6.webp" />
             <Card.Body>
                <Card.Title>Loan Advisory <Card.Text>We Navigate Your Loan Journey with Expert Advisory</Card.Text></Card.Title>
                    
                    <ListGroup variant="flush">

                            <ListGroup.Item><a href="/loan_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>  Home loans</a></ListGroup.Item>
                            <ListGroup.Item><a href="/loan_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   Medical loans</a></ListGroup.Item>
                            <ListGroup.Item><a href="/loan_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   Personal loans</a></ListGroup.Item>
                            <ListGroup.Item><a href="/loan_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   Education loans</a></ListGroup.Item>
                            <ListGroup.Item><a href="/loan_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   Wedding loans .etc</a></ListGroup.Item>
                        </ListGroup>
                    
                <a href="/loan_advisory" class="btn btn-primary">Read More..</a>
            </Card.Body>
        </Card>
        </Col>
        <Col className='card12'>
        <Card>
            <Card.Img variant="top" alt='services' src="assets/1.webp" />
             <Card.Body>
                <Card.Title>Financial Advisory <Card.Text>We build the Path to Your Financial Excellence</Card.Text></Card.Title>
                

                    <ListGroup variant="flush">
                    <ListGroup.Item><a href="/financial_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   Financial planning</a> </ListGroup.Item>
                    <ListGroup.Item><a href="/financial_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   Retirement planning</a> </ListGroup.Item>
                    <ListGroup.Item><a href="/financial_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   Financial Consultant</a> </ListGroup.Item>
                    <ListGroup.Item><a href="/financial_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   Investment advisors</a> </ListGroup.Item>
                    <ListGroup.Item><a href="/financial_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   Tax planning etc.</a> </ListGroup.Item>
                    </ListGroup>
                <a href="/financial_advisory" class="btn btn-primary">Read More..</a>
            </Card.Body>
        </Card>
        </Col>
        <Col className='card12'>
        <Card>
            <Card.Img variant="top" alt='services' src="assets/3.webp" />
             <Card.Body>
                <Card.Title>Tax Advisory <Card.Text>We Guiding Your Tax Through Expert Advisory</Card.Text></Card.Title>
                    

                    <ListGroup variant="flush">
                        <ListGroup.Item><a href="/tax_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   Income tax planning & filing</a> </ListGroup.Item>
                        <ListGroup.Item><a href="/tax_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   Personal tax </a> </ListGroup.Item>
                     
                        <ListGroup.Item><a href="/tax_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   Corporate tax</a> </ListGroup.Item>
                        <ListGroup.Item><a href="/tax_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   NRI & foreign company advisory</a> </ListGroup.Item>
                        <ListGroup.Item><a href="/tax_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   GST,insurance,mutual fund etc.</a> </ListGroup.Item>
                    </ListGroup>
                <a href="/tax_advisory" class="btn btn-primary">Read More..</a>
            </Card.Body>
        </Card>
        </Col>
        <Col className='card12'>
        <Card>
            <Card.Img variant="top" alt='services' src="assets/4.webp" />
             <Card.Body>
                <Card.Title>Business Advisory <Card.Text>We Navigate Your Business with Expert Advisory</Card.Text></Card.Title>
                    

                    <ListGroup variant="flush">
                        <ListGroup.Item><a href="/business_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   Growth strategist</a> </ListGroup.Item>
                        <ListGroup.Item><a href="/business_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   Cashflow Management </a> </ListGroup.Item>
                        <ListGroup.Item><a href="/business_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   Financing</a> </ListGroup.Item>
                        <ListGroup.Item><a href="/business_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   Startup Business Advisory</a> </ListGroup.Item>
                        <ListGroup.Item><a href="/business_advisory" className="text-decoration-none text-reset"><i className='ok-icon'></i>   IT Advisory etc.</a> </ListGroup.Item>
                    </ListGroup>
                <a href="/business_advisory" class="btn btn-primary">Read More..</a>
            </Card.Body>
        </Card>
      
        </Col>
              
               </Container>
    </div> 
            <Contactnow/>
        </div>
        </div>
    )
}

export default Contact;